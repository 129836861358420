/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';

/** @namespace Satisfly/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    static propTypes = {
        ...super.propTypes,
        preventGoBack: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        preventGoBack: false
    };

    containerProps() {
        const superContainerProps = super.containerProps();
        const {
            preventGoBack
        } = this.props;

        return {
            ...superContainerProps,
            preventGoBack
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
