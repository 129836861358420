/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './RadioButtonIcon.style';

/** @namespace Satisfly/Component/RadioButtonIcon/Component */
export class RadioButton extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="RadioButtonIcon"
              mods={ { isActive } }
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <circle block="RadioButtonIcon" elem="Circle" cx="9" cy="9" r="8" stroke="#C48F7F" strokeWidth="2" />
                <circle block="RadioButtonIcon" elem="Dot" cx="9" cy="9" r="4.5" fill="#C48F7F" />
            </svg>
        );
    }
}

export default RadioButton;
