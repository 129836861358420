/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

/** @namespace Satisfly/Component/MyAccountAddressTable/Table/getAddressTablePairArray */
export const getAddressTablePairArray = (props) => {
    const { address } = props;
    const formattedStreet = address.street.join('/');

    return [
        {
            key: 'company',
            label: __('company name'),
            source: address
        },
        {
            key: 'vat_id',
            label: __('VAT Number'),
            source: address
        },
        {
            key: 'firstname',
            label: __('first name'),
            source: address
        },
        {
            key: 'lastname',
            label: __('last name'),
            source: address
        },
        {
            key: 'street',
            label: __('street'),
            source: { street: formattedStreet }
        },
        {
            key: 'postcode',
            label: __('postcode'),
            source: address
        },
        {
            key: 'city',
            label: __('city'),
            source: address
        },
        {
            key: 'telephone',
            label: __('phone number'),
            source: address
        }
    ];
};

export default getAddressTablePairArray;
