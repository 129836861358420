/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Field from 'Component/Field';
import FieldGroup from 'Component/FieldGroup';
import { FieldForm as SourceFieldForm } from 'SourceComponent/FieldForm/FieldForm.component';

import { COMPONENT_TYPE } from './FieldForm.config';

/** @namespace Satisfly/Component/FieldForm/Component */
export class FieldForm extends SourceFieldForm {
    renderSection(section) {
        const {
            fields,
            attr: {
                name = ''
            } = {},
            name: sectionName,
            type
        } = section;

        if (type === COMPONENT_TYPE) {
            return section?.content;
        }

        // If contains attr fields then outputs data as fields
        if (Array.isArray(fields)) {
            return (
                <FieldGroup { ...section } key={ name || sectionName }>
                    { fields.map(this.renderSection.bind(this)) }
                </FieldGroup>
            );
        }

        return <Field { ...section } key={ name } />;
    }
}

export default FieldForm;
